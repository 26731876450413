<template>
  <b-modal
    size="md"
    id="bulkZuweisenModal"
    :title="selectedAufgabenCount + ' Aufgaben neu zuweisen'"
    centered
    @hide="onHide"
  >
    <div>
      <h5>Bearbeiter</h5>
      <div class="d-flex">
        <UsersMultiselect
          v-model="bearbeiter"
          :options="zugewiesenePersonOptions"
          class="mr-2 border"
          style="font-size: 14px; height: 36px; flex-grow: 1; border-radius: 8px"
          :placeholder="'Wähle einen Bearbeiter...'"
        ></UsersMultiselect>
        <b-button
          @click="bearbeiter = currentUserId"
          style="height: 38px"
          size="sm"
          :variant="bearbeiter === currentUserId ? 'secondary' : 'primary'"
          :disabled="bearbeiter === currentUserId"
        >
          Ich
        </b-button>
        <i
          v-if="bearbeiter !== null"
          class="fas fa-times text-danger align-self-center ml-2"
          @click="bearbeiter = null"
          style="cursor: pointer"
        ></i>
      </div>
    </div>
    <template #modal-footer="{ hide }">
      <b-button
        @click="neuZuweisen(hide)"
        class="mr-4"
        :disabled="bearbeiter === null"
        :variant="bearbeiter !== null ? 'primary' : ''"
        >Sichern</b-button
      >
      <b-button @click="hide">Abbrechen</b-button>
    </template>
  </b-modal>
</template>
<script>
import apiService from '@/core/common/services/api.service';
import { mapState, mapGetters } from 'vuex';
import { changePeopleToCorrectShape, permittedDepartments } from '@/core/common/helpers/utils.js';
import { GET_AUFGABEN_NOTIFICATION_COUNT } from '@/core/aufgaben/stores/aufgaben.module';
import UsersMultiselect from '@/components/common/users-multiselect.vue';

export default {
  props: { selectedAufgabenCount: { type: Number }, selectedAufgabenIds: { type: Array, required: true } },
  components: { UsersMultiselect },
  data() {
    return {
      bearbeiter: null,
    };
  },
  computed: {
    ...mapState({
      users: state => state.users['users'],
    }),
    ...mapGetters(['parsedRoles', 'currentUserId']),

    zugewiesenePersonOptions() {
      if (this.isAdmin) {
        return changePeopleToCorrectShape(this.users);
      } else {
        return changePeopleToCorrectShape(this.peopleWithRestrictedPermission) || [];
      }
    },
    isAdmin() {
      return this.parsedRoles.includes('Admin');
    },
    peopleWithRestrictedPermission() {
      return this.users?.filter(
        user => permittedDepartments.includes(user.department) || user.id === this.userIdWebstada
      );
    },
  },
  methods: {
    async neuZuweisen(hide) {
      const confirmed = await this.$bvModal.msgBoxConfirm(
        'Willst du die ausgewählten Aufgaben wirklich neu zuweisen?',
        {
          centered: true,
          okTitle: 'OK',
          cancelTitle: 'Abbrechen',
        }
      );
      if (confirmed) {
        apiService
          .put('/aufgaben/bulk', { ids: this.selectedAufgabenIds, bearbeiter: this.bearbeiter })
          .then(() => {
            this.toast('Erfolgreich zugewiesen.');
            hide();
            this.$emit('neuZugewiesen');
            this.$store.dispatch(GET_AUFGABEN_NOTIFICATION_COUNT);
          });
      }
    },
    onHide() {
      this.bearbeiter = null;
    },
  },
};
</script>
