<template>
  <div class="row" style="height: 90%; width: 200px; line-height: normal; margin: 8px 0px">
    <div
      class="mr-1 p-0 d-flex"
      :id="'zimmerkontingent-' + zimmerkontingent.zimmertyp + '-' + reiseterminId"
      v-for="zimmerkontingent in validZimmerkontingente"
      :key="zimmerkontingent.zimmertyp + '-' + reiseterminId + '-' + zimmerkontingent.partner"
    >
      <div>
        <div class="einheiten-frei rounded" :class="istBgColor(zimmerkontingent) + ' mr-1'">
          {{
            zimmerkontingent.verfuegbarkeitsStatus == 'Verfuegbar'
              ? zimmerkontingent.einheitenFrei
              : zimmerkontingent.verfuegbarkeitsStatus == 'NichtVerfuegbar'
              ? 'N'
              : 'RQ'
          }}
        </div>
        <div class="text-center zimmertyp">
          {{ zimmerkontingent.zimmertyp }}
          <i
            v-if="zimmerkontingent.kontingentAktuellGeteilt"
            class="fas fa-asterisk text-danger icon-sm position-absolute translate-middle"
            :title="`Geteiltes Kontingent: ${zimmerkontingent.kontingentAktuellGeteilt}`"
          ></i>
        </div>
      </div>

      <b-tooltip
        custom-class="custom-tooltip-zimmerkontingent-widget"
        :target="'zimmerkontingent-' + zimmerkontingent.zimmertyp + '-' + reiseterminId"
        v-if="isZvc"
        placement="left"
        @show="loadZuschlagHistory(zimmerkontingent.id)"
        @hide="dataLineChart = []"
      >
        <span>Einheiten frei: {{ zimmerkontingent.einheitenFrei }}</span>
        <LineChart
          v-if="dataLineChart.length > 0"
          style="width: 300px; height: 200px; margin: 0; padding: 0"
          :datapoints="dataLineChart"
          :showLegend="false"
        ></LineChart>
      </b-tooltip>
      <b-tooltip
        placement="left"
        :target="'zimmerkontingent-' + zimmerkontingent.zimmertyp + '-' + reiseterminId"
        v-else
      >
        <span
          >Zuschlag {{ zimmerkontingent.zimmertyp }}:
          {{ !isNaN(zimmerkontingent.zuschlag) ? zimmerkontingent.zuschlag + '€' : '' }}</span
        >
      </b-tooltip>
    </div>
  </div>
</template>

<script>
import apiService from '@/core/common/services/api.service';
import LineChart from '@/components/produkte/reisetermine/line-chart.vue';
import { format } from 'date-fns';

export default {
  props: {
    zimmerkontingente: { type: Array, required: true },
    reiseterminId: { type: Number, required: true },
    isZvc: { type: Boolean },
  },
  data() {
    return {
      dataLineChart: [],
    };
  },
  components: {
    LineChart,
  },
  computed: {
    validZimmerkontingente() {
      if (this.zimmerkontingente) {
        return this.zimmerkontingente
          .filter(z => z.isDeleted === false)
          .sort((a, b) => {
            const order = { DZ: 1, EZ: 2 };
            const orderA = order[a.zimmertyp] || 3;
            const orderB = order[b.zimmertyp] || 3;

            if (orderA !== orderB) {
              return orderA - orderB;
            } else {
              return a.zimmertyp.localeCompare(b.zimmertyp);
            }
          });
      } else return [];
    },
  },
  methods: {
    loadZuschlagHistory(id) {
      apiService.get('/history/zimmerkontingente/' + id).then(response => {
        const { result } = response.data;
        const aufpreiseChanges = [];
        for (const key in result) {
          const filteredZuschlag = result[key].filter(item => item.key === 'EinheitenFrei');
          if (filteredZuschlag.length > 0) {
            filteredZuschlag.forEach(item => {
              const laterKeyDate = format(new Date(key), 'dd.MM. HH:mm');
              aufpreiseChanges.push({ date: laterKeyDate, value: item.newValue });
            });
          }
        }
        this.dataLineChart = aufpreiseChanges;
      });
    },
    istBgColor(zimmerkontingent) {
      return zimmerkontingent.verfuegbarkeitsStatus == 'Verfuegbar'
        ? 'green'
        : zimmerkontingent.verfuegbarkeitsStatus == 'NichtVerfuegbar'
        ? 'bg-danger'
        : 'gray';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/components/_variables.bootstrap.scss';

.zimmertyp {
  font-size: 0.9rem;
  line-height: 15px;
}
.column {
  flex: 1;
}
.tooltip.show {
  opacity: 1 !important;
}
.red {
  background-color: $danger;
}
.yellow {
  background-color: $warning;
}
.green {
  background-color: $success;
}
.gray {
  background-color: #e5eaee;
}
.tooltip {
  opacity: 1 !important;
}
.einheiten-frei {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 30px;
  height: 25px;
  font-size: 1rem;
}
</style>
<style>
.custom-tooltip-zimmerkontingent-widget > .tooltip-inner {
  max-width: none;
  width: 500;
  font-size: 14px;
}
</style>
